<template>
  <div class="page">
    <div class="c-jumio">
      <div class="c-jumio__description">
        <div class="c-jumio__content">
          <img class="c-jumio__img" src="@/assets/images/old-alpha/jumio-success.png" alt="jumio-success">
          <span class="c-jumio__success-text">Thank you</span>
        </div>
        <p class="c-jumio__desc">Your verification is now being processed.</p>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'JumioSuccess',
  components: {
  },
  data() {
    return {
    }
  },
  mounted:function() {
    window.parent.postMessage("jumio-success-1", location.origin);
  }
}
</script>
